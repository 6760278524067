import { navigate } from "gatsby"
import React, { useEffect } from "react"
import Layout from "../components/Layout"

export default function Home({ data }) {
  useEffect(() => {
    navigate("/wip")
  }, [])
  return (
    <>
      <Layout>{/* <h1>Hello World!</h1> */}</Layout>
    </>
  )
}
